import { useState, useRef, useCallback } from 'react';
import { Divider, Grid, Col, Timeline, Text, Button, Image, Radio, Title } from '@mantine/core';
import { formatDistance } from 'date-fns';
import { MarkGithubIcon } from '@primer/octicons-react';
import useFetch, { defaultLastKey } from './lib/useFetch';
import repositoryList, { Repository } from './lib/repositoryList';

const sortRepositories = (a: Repository, b: Repository) => {
  return a.description.localeCompare(b.description);
}

function App() {
  const [lastKey, setLastKey] = useState(defaultLastKey);
  const [repositories, setRepositories] = useState<Repository[]>(repositoryList.sort(sortRepositories));
  const [type, setType] = useState('ALL');
  const [currentRepository, setCurrentRepository] = useState<string | undefined>(undefined);
  const [cleanHistory, setCleanHistory] = useState<boolean>(false);

  const { isLoading, repoVersions, hasMore, hasLastKey } = useFetch(lastKey, type, currentRepository, cleanHistory);
  const observer = useRef<any>();

  const lastElementRef = useCallback((node: any) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLastKey(hasLastKey);
          setCleanHistory(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasLastKey, hasMore]
  );

  const currentTimestamp = new Date().getTime();

  const getSinceTime = (isoDate: Date): string => {
    return formatDistance(
      new Date(isoDate).getTime(),
      currentTimestamp,
      { addSuffix: true }
    )
  }

  // const applyRepoFilter = (repoIdentifier: string) => {
  //   setCurrentRepository(repoIdentifier);
  //   setLastKey(undefined);
  //   setCleanHistory(true);
  // }

  const applyTypeFilter = (type: string) => {
    setType(type);

    if (type === 'ALL' && !currentRepository) {
      setRepositories(repositoryList.sort(sortRepositories));
      setCurrentRepository(undefined);
      setLastKey(undefined);
      setCleanHistory(true);
    } else {
      setRepositories(repositoryList.filter(r => r.type === type).sort(sortRepositories));
      setLastKey(undefined);
      setCleanHistory(true);
      setCurrentRepository(undefined);
    }
  }

  return (
    <>
      <Grid>
        <Col span={12} style={{ minHeight: 60, display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
          <Image
            width={259}
            height={32}
            src="images/logo.png"
            style={{ marginLeft: 20 }}
          />
        </Col>        
      </Grid>

      <Divider variant="dashed" />

      <Grid justify="center" style={{ marginTop: 20 }}>
        <Col span={12} md={9} lg={6}>
          <Title order={3} style={{ marginBottom: 20 }}>Repository release timeline</Title>
          <Timeline active={0} bulletSize={24} lineWidth={2}>
            {repoVersions.map((version, index) => (
              <Timeline.Item key={index} bullet={<MarkGithubIcon size={12} />} title={version.description}>
              <Text color="dimmed" size="sm">{version.authorName} created new {version.gitHubDataType} <Text weight={500} component="span" inherit>'{version.name}'</Text> for <Text size="sm" variant="link" component="a" href={version.url}>{version.gitHubOrganizationName}/{version.gitHubRepoName}</Text></Text>
              <Text size="xs" style={{ marginTop: 4 }}>{getSinceTime(version.publishedAt)}</Text>
            </Timeline.Item>
            ))}
          </Timeline>
          <div className="loading" ref={lastElementRef}>
            <Button variant="outline" radius="lg" style={{ marginTop: 20 }}>Load more</Button>
          </div>
        </Col>
        <Col span={12} md={3} lg={3}>
          <Title order={3}>Filter by repository type</Title>
          <Radio.Group
            value={type}
            onChange={applyTypeFilter}
            label="Select the type of repositories you want to see"
          >
            <Radio value="ALL" label="All" />
            <Radio value="SDK" label="SDKs" />
            <Radio value="CLI" label="CLIs" />
          </Radio.Group>
        </Col>
      </Grid>
    </>
  );
}

export default App;
