import { useEffect, useState, useCallback } from 'react';

export interface RepoVersion {
  tagCommitMessage: string;
  cloudProvider: string;
  url: string;
  name: string;
  gitHubDataType: string;
  authorName: string;
  filter: string;
  tagCommitAuthorAvatarUrl: string;
  id: string;
  isRecurrentQuery: boolean;
  nameSemverVersion: string;
  createdAt: Date;
  tagCommitAuthorName: string;
  gitHubOrganizationName: string;
  tagName: string;
  tagId: string;
  authorAvatarUrl: string;
  updatedAt: Date;
  publishedAt: Date;
  commitedAt: Date;
  tagSemverVersion: string;
  description: string;
  gitHubRepoName: string;
  type: string;
}

export interface LastKey {
  partitionKey: string;
  sortKey: string;
  typePartitionKey: string;
  typeSortKey: string;
}

export interface RepoVersionsResponse {
  data: RepoVersion[];
  lastKey?: LastKey;
}

export const defaultRepoVersions: RepoVersion[] = [];
export const defaultLastKey: LastKey | undefined = undefined;

function useFetch(lastKey: LastKey | undefined, type: string, currentRepository: string | undefined, cleanHistory: boolean = false) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [repoVersions, setRepoVersions] = useState(defaultRepoVersions);
  const [hasMore, setHasMore] = useState(false);
  const [nexLastKey, setNextLastKey] = useState(defaultLastKey);

  const baseUrl: string = 'https://api.publiccloudbot.com/v1/repos/versions';
  const limit: number = 15;

  const getUrl = useCallback(() => {
    let url = `${baseUrl}?limit=${limit.toString()}`;

    if (type === 'ALL') {
      // Do nothing
    } else if (currentRepository) {
      const repoData = currentRepository.split('/');
      url += `&providerName=${repoData[0]}&orgName=${repoData[1]}&repoName=${repoData[2]}`;
    } else {
      url += `&type=${type}`;
    }

    if (lastKey) {
      url = `${url}&lastKey=${encodeURIComponent(JSON.stringify(lastKey))}`;
    }

    return url;
  }, [lastKey, type, currentRepository]);

  const sendQuery = useCallback(async () => {
    try {
      await setIsLoading(true);
      await setError(false);

      const url = getUrl();

      fetch(url, {
        method: 'GET',
        mode: 'cors'
      })
        .then(response => response.json())
        .then(parsedResponse => {
          console.log(parsedResponse);
          if (cleanHistory) {
            setRepoVersions(parsedResponse.data);
          } else {
            setRepoVersions((prev) => [...prev, ...parsedResponse.data]);
          }
          if (parsedResponse.lastKey) {
            setHasMore(true);
            setNextLastKey(parsedResponse.lastKey);
          }
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
      setError(true);
    }
  }, [getUrl, cleanHistory]);

  useEffect(() => {
    sendQuery();
  }, [sendQuery, lastKey]);

  return { isLoading, error, repoVersions, hasMore, hasLastKey: nexLastKey };
}

export default useFetch;
