export interface Repository {
  description: string;
  type: string;
  cloudProvider: string;
  gitHubOrganizationName: string;
  gitHubRepoName: string;
  gitHubDataType: string;
  filter: string;
}

const repositoryList: Repository[] = [
  {
    description: "Microsoft Azure SDK for .NET",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-net",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for Go",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-go",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for C",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-c",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for C++",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-cpp",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for iOS",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-ios",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for Android",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-android",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for Java",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-java",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for JavaScript",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-js",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure SDK for Python",
    type: "SDK",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-sdk-for-python",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for C++",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-cpp",
    gitHubDataType: "tags",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Go",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-go",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Java",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-java",
    gitHubDataType: "tags",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Javascript",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-js",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Javascript v3",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-js-v3",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for .NET",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-net",
    gitHubDataType: "tags",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for PHP",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-php",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Python",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "boto",
    gitHubRepoName: "boto3",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Ruby",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sdk-ruby",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for Go",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-go",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for Java",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-java",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for Node.js",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-node",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for Python",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-python",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for Ruby",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-ruby",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for PHP",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-php",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for .NET",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-dotnet",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Google Cloud SDK for C++",
    type: "SDK",
    cloudProvider: "GCP",
    gitHubOrganizationName: "googleapis",
    gitHubRepoName: "google-cloud-cpp",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS CLI",
    type: "CLI",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-cli",
    gitHubDataType: "tags",
    filter: "no-prerelease"
  },
  {
    description: "AWS CDK",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-cdk",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Rust",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "awslabs",
    gitHubRepoName: "aws-sdk-rust",
    gitHubDataType: "tags",
    filter: "no-prerelease"
  },
  {
    description: "AWS SDK for Swift",
    type: "SDK",
    cloudProvider: "AWS",
    gitHubOrganizationName: "awslabs",
    gitHubRepoName: "aws-sdk-swift",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "Microsoft Azure CLI",
    type: "CLI",
    cloudProvider: "Azure",
    gitHubOrganizationName: "Azure",
    gitHubRepoName: "azure-cli",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
  {
    description: "AWS SAM CLI",
    type: "CLI",
    cloudProvider: "AWS",
    gitHubOrganizationName: "aws",
    gitHubRepoName: "aws-sam-cli",
    gitHubDataType: "releases",
    filter: "no-prerelease"
  },
];


export default repositoryList;